/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import headerTechSvg from '../img/headers/header-tech.svg';
import techPostThumbnailSvg from '../img/illustrations/tech-post-thumbnail.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import AspectRatioImg from '../components/AspectRatioImg';
import TextLink from '../components/TextLink';
import ArticleTools from '../components/ArticleTools';

export const TechPostsPageTemplate = ({ latestPosts }) => (
  <Container>
    <AspectRatioImg src={headerTechSvg} alt="" ratio={912 / 256} />
    <div sx={{ mt: 13 }}>
      {latestPosts.map((post, i) => (
        <Fragment key={post.id}>
          {i > 0 && (
            <hr sx={{ height: '1px', backgroundColor: 'muted', my: 9 }} />
          )}
          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridGap: 11,
            }}
          >
            <div>
              <h2>
                <TextLink
                  to={post.fields.url}
                  sx={{
                    display: 'block',

                    fontSize: '21px',
                    lineHeight: 'heading',
                    mb: 4,
                  }}
                >
                  {post.frontmatter.title}
                </TextLink>
              </h2>
              {post.frontmatter.subtitle && (
                <h3
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'heading',
                    mb: 4,
                  }}
                >
                  {post.frontmatter.subtitle}
                </h3>
              )}
              <div sx={{ display: 'flex', ml: -2 }}>
                <ArticleTools post={post} />
              </div>
            </div>
            <Link to={post.fields.url} title={post.frontmatter.title}>
              <AspectRatioImg
                src={techPostThumbnailSvg}
                alt=""
                ratio={592 / 363}
              />
            </Link>
          </div>
        </Fragment>
      ))}
    </div>
  </Container>
);

const TechPostsPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    latestPosts,
  } = data;

  return (
    <Layout
      page="tech-posts"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <TechPostsPageTemplate latestPosts={latestPosts.nodes} />
    </Layout>
  );
};

TechPostsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TechPostsPage;

export const pageQuery = graphql`
  query TechPostsPageQuery(
    $id: String!
    $language: String!
    $currentDate: Date!
  ) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
      }
    }
    latestPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 25
      filter: {
        fields: { sourceInstanceName: { eq: "tech-posts" } }
        frontmatter: {
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
        }
        frontmatter {
          title
          subtitle
          type
        }
        timeToRead
      }
    }
  }
`;
